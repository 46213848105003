import { Link, Outlet } from "react-router-dom";
import { FaGithub, FaLinkedin } from "react-icons/fa6";
import { MdNotifications, MdOutlineEdit, MdSettings } from "react-icons/md";
import { useIntl, FormattedMessage, FormattedDate } from "react-intl";
import { useEntityScaffoldQuery } from "./loaders/EntityScaffold";
import ErrorPage from "./ErrorPage";
import EntityProfilePic from "../components/EntityProfilePic";
import NavItem from "../components/NavItem";
import { Dropdown } from "../components/Dropdown";
import { ToolbarButton } from "../components/Button";
import { ScrollableScaffoldNav } from "../components/ScrollableScaffoldNav";
import EntityHelmet from "../components/EntityHelmet";

export default function EntityScaffold() {
  const intl = useIntl();
  const { variables, query } = useEntityScaffoldQuery();
  if (!query.entityByUsername) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }
  const entity = query.entityByUsername;
  return (
    <>
      <EntityHelmet entity={entity} />
      <div className="space-y-6">
        <div className="flex flex-row space-x-6 items-center">
          <div className="pr-6">
            <EntityProfilePic entity={entity} size="28" />
          </div>
          <div className="flex-grow inline-flex flex-col">
            <h1 className="text-lg font-bold font-poppins">
              {entity.displayName}
            </h1>
            {entity.jobTitle ||
              (entity.organization && (
                <h2>
                  {entity.jobTitle}
                  {entity.jobTitle && entity.organization && " | "}
                  {entity.organization}
                </h2>
              ))}
            {entity.location && <h2>{entity.location}</h2>}
            {entity.createdAt && (
              <h3>
                <FormattedMessage defaultMessage="Joined" />{" "}
                <FormattedDate
                  value={entity.createdAt}
                  month="long"
                  day="numeric"
                  year="numeric"
                />
              </h3>
            )}
            <div className="flex space-x-2 items-center">
              {entity.github && (
                <Link
                  to={`https://github.com/${entity.github}`}
                  target="_blank"
                >
                  {<FaGithub />}
                </Link>
              )}
              {entity.linkedin && (
                <Link
                  to={`https://linkedin.com/${entity.linkedin}`}
                  target="_blank"
                >
                  {
                    <FaLinkedin
                      title={intl.formatMessage(
                        { defaultMessage: "Linkedin profile of {username}" },
                        { username: entity.username },
                      )}
                    />
                  }
                </Link>
              )}
              {entity.website && (
                <Link to={entity.website} target="_blank">
                  {entity.website}
                </Link>
              )}
            </div>
          </div>
          {entity.viewerCanEdit && (
            <SettingsButton
              username={entity.username}
              canConfigureNotifications={
                !!entity.viewerCanConfigureNotifications
              }
            />
          )}
        </div>
        <div>
          <ScrollableScaffoldNav>
            <NavItem to={`/${entity.username}`} exact>
              <FormattedMessage defaultMessage="Overview" />
            </NavItem>
            <NavItem to={`/${entity.username}/submissions`}>
              <FormattedMessage defaultMessage="Last submissions" />
            </NavItem>
            {entity.kind == "USER" && (
              <NavItem to={`/${entity.username}/comments`}>
                <FormattedMessage defaultMessage="Last comments" />
              </NavItem>
            )}
            {entity.kind == "USER" && (
              <NavItem to={`/${entity.username}/topics`}>
                <FormattedMessage defaultMessage="Last topics" />
              </NavItem>
            )}
            {entity.kind == "USER" && (
              <NavItem to={`/${entity.username}/organizations`}>
                <FormattedMessage defaultMessage="Organizations" />
              </NavItem>
            )}
            {entity.kind == "ORGANIZATION" && (
              <NavItem to={`/${entity.username}/users`}>
                <FormattedMessage defaultMessage="Users" />
              </NavItem>
            )}
          </ScrollableScaffoldNav>
          <div className="pt-5 pb-8">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

interface SettingsButtonProps {
  username: string;
  canConfigureNotifications: boolean;
}

const SettingsButton = ({
  username,
  canConfigureNotifications,
}: SettingsButtonProps) => (
  <Dropdown
    trigger={
      <ToolbarButton>
        <MdSettings />
      </ToolbarButton>
    }
  >
    <Dropdown.List header={<FormattedMessage defaultMessage="Settings" />}>
      <Link to={`/${username}/edit`}>
        <Dropdown.Item icon={<MdOutlineEdit />}>
          <FormattedMessage defaultMessage="Edit Profile" />
        </Dropdown.Item>
      </Link>

      {canConfigureNotifications && (
        <Link to="/settings/notifications">
          <Dropdown.Item icon={<MdNotifications />}>
            <FormattedMessage defaultMessage="Notifications" />
          </Dropdown.Item>
        </Link>
      )}
    </Dropdown.List>
  </Dropdown>
);
