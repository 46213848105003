/**
 * @generated SignedSource<<41963b74a83fdb62c02e4a0299c5d084>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VotableOrder = "HOT" | "NEWEST" | "OLDEST";
export type CompetitionTopicsPageQuery$variables = {
  order: VotableOrder;
  slug: string;
};
export type CompetitionTopicsPageQuery$data = {
  readonly competitionBySlug: {
    readonly forum: {
      readonly " $fragmentSpreads": FragmentRefs<"TopicsPageFragment">;
    };
  } | null | undefined;
};
export type CompetitionTopicsPageQuery = {
  response: CompetitionTopicsPageQuery$data;
  variables: CompetitionTopicsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v3/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "TopicsPageFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/),
              {
                "alias": "viewerCanCreateTopic",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "CREATE_TOPIC"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"CREATE_TOPIC\")"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "TopicConnection",
                "kind": "LinkedField",
                "name": "topics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commentCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Forum",
                            "kind": "LinkedField",
                            "name": "forum",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v10/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEntity"
                              },
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageThumbnail",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              },
                              {
                                "kind": "Literal",
                                "name": "order",
                                "value": "NEWEST"
                              }
                            ],
                            "concreteType": "CommentConnection",
                            "kind": "LinkedField",
                            "name": "comments",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Comment",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "comments(first:1,order:\"NEWEST\")"
                          },
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "votes",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EntityVote",
                                "kind": "LinkedField",
                                "name": "voted",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "score",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": "viewerCanVote",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "action",
                                    "value": "PUBLISH_VOTE"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "viewerCan",
                                "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
                              }
                            ],
                            "type": "Votable",
                            "abstractKey": "__isVotable"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "order"
                ],
                "handle": "connection",
                "key": "ForumTopicsFragment_topics",
                "kind": "LinkedHandle",
                "name": "topics"
              },
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "viewerCanSubscribe",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "CREATE_SUBJECT_SUBSCRIPTION"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"CREATE_SUBJECT_SUBSCRIPTION\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "entitySubscription",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": "viewerCanUnsubscribe",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "DELETE_SUBJECT_SUBSCRIPTION"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"DELETE_SUBJECT_SUBSCRIPTION\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Subscribable",
                "abstractKey": "__isSubscribable"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f51a76a20c5693ca96c32d43cbd46f8",
    "id": null,
    "metadata": {},
    "name": "CompetitionTopicsPageQuery",
    "operationKind": "query",
    "text": "query CompetitionTopicsPageQuery(\n  $slug: String!\n  $order: VotableOrder!\n) {\n  competitionBySlug(slug: $slug) {\n    forum {\n      ...TopicsPageFragment_17VYVu\n      id\n    }\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment ForumTopicsFragment_17VYVu on Forum {\n  topics(first: 10, order: $order) {\n    edges {\n      node {\n        id\n        ...TopicListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment SubjectSubscriptionButtonFragment on Subscribable {\n  __isSubscribable: __typename\n  id\n  viewerCanSubscribe: viewerCan(action: CREATE_SUBJECT_SUBSCRIPTION)\n  entitySubscription @ifAllowed {\n    __typename\n    id\n    kind\n    viewerCanUnsubscribe: viewerCan(action: DELETE_SUBJECT_SUBSCRIPTION)\n  }\n}\n\nfragment TopicListItemFragment on Topic {\n  id\n  title\n  url\n  createdAt\n  commentCount\n  forum {\n    slug\n    owner {\n      __typename\n      slug\n      id\n    }\n    id\n  }\n  author {\n    __typename\n    username\n    ...EntityProfilePicFragment_49kWkb\n    id\n  }\n  comments(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        username\n        displayName\n        id\n      }\n      id\n    }\n  }\n  ...VoteDisplayFragment\n}\n\nfragment TopicsPageFragment_17VYVu on Forum {\n  slug\n  owner {\n    __typename\n    slug\n    id\n  }\n  viewerCanCreateTopic: viewerCan(action: CREATE_TOPIC)\n  ...ForumTopicsFragment_17VYVu\n  ...SubjectSubscriptionButtonFragment\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "ae88070211e0d2234979dad8ae44f23f";

export default node;
