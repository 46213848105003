import { useIntl, defineMessages, IntlShape } from "react-intl";
import { useLocation, useMatches } from "react-router-dom";
import { RouteHandle, isRouteHandle } from "./route";

const metaTagMessages = defineMessages({
  profileTitle: {
    defaultMessage: "Meet {name}",
  },
  profileTitleWithJobTitle: {
    defaultMessage: "Meet {name}, {job}",
  },
  profileDescription: {
    defaultMessage:
      "Meet {name}. Learn more about {namePossessive} contributions to the quantum computing field.",
  },
  subpageTitle: {
    defaultMessage: "{namePossessive} {subpage} | Aqora.io",
  },
  subpageDescription: {
    defaultMessage:
      "Explore {namePossessive} {subpage}, and contributions to the field of quantum computing at Aqora.io.",
  },
  eventsPageTitle: {
    defaultMessage: "Quantum Computing Events on Aqora.io | Join now",
  },
  eventsPageDescription: {
    defaultMessage:
      "Participate in quantum computing hackathons at Aqora.io. Demonstrate your skills, collaborate with experts, and push the boundaries of innovation.",
  },
  eventTitle: {
    defaultMessage: "{event} | Aqora.io",
  },
  eventDescription: {
    defaultMessage:
      "Join the {event} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  eventSubpageTitle: {
    defaultMessage: "{event} on Aqora.io | {subpage}",
  },
  eventSubpageDescription: {
    defaultMessage:
      "{event}: Checkout the {subpage} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  competitionTitle: {
    defaultMessage: "{competition}: Online Quantum Competition | Aqora.io",
  },
  competitionDescription: {
    defaultMessage:
      "{competition}: Join the online quantum competition on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
  competitionSubpageTitle: {
    defaultMessage: "{competition} on Aqora.io | {subpage}",
  },
  competitionSubpageDescription: {
    defaultMessage:
      "{competition}: Checkout the {subpage} on Aqora.io. Designed for quantum computing professionals and enthusiasts.",
  },
});

export interface MetaTags {
  metaTitle: string;
  metaDescription: string;
}

interface BaseMetaTags {
  intl: IntlShape;
  matchedRoute?: RouteHandle;
  subpage: string;
  pathname: string;
}

const getPathSegments = (pathname: string, depth: number): string[] =>
  pathname.split("/").filter(Boolean).slice(0, depth);

const getNamePossessive = (name: string): string =>
  `${name}${name.endsWith("s") ? "'" : "'s"}`;

function useBaseMetaTags(): BaseMetaTags {
  const intl = useIntl();
  const matches = useMatches();
  const { pathname } = useLocation();

  const matchedRoute = matches
    .slice()
    .reverse()
    .find((match) => isRouteHandle(match.handle))?.handle as
    | RouteHandle
    | undefined;

  const subpage = matchedRoute
    ? intl.formatMessage(matchedRoute.titleI18n).toLowerCase()
    : "";

  return { intl, matchedRoute, subpage, pathname };
}

export function useProfileMetaTags(
  fullname: string,
  job?: string | null,
): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[1];

  const namePossessive = getNamePossessive(fullname);

  const metaTitle = isSubpage
    ? intl.formatMessage(metaTagMessages.subpageTitle, {
        namePossessive,
        subpage,
      })
    : job
      ? intl.formatMessage(metaTagMessages.profileTitleWithJobTitle, {
          name: fullname,
          job,
        })
      : intl.formatMessage(metaTagMessages.profileTitle, { name: fullname });

  const metaDescription = isSubpage
    ? intl.formatMessage(metaTagMessages.subpageDescription, {
        namePossessive,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.profileDescription, {
        namePossessive,
        name: fullname,
      });

  return { metaTitle, metaDescription };
}

export function useEventsMetaTags(title?: string): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[2];

  if (!title) {
    return {
      metaTitle: metaTagMessages.eventsPageTitle.defaultMessage,
      metaDescription: metaTagMessages.eventsPageDescription.defaultMessage,
    };
  }

  const metaTitle = isSubpage
    ? intl.formatMessage(metaTagMessages.eventSubpageTitle, {
        event: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.eventTitle, {
        event: title,
      });

  const metaDescription = isSubpage
    ? intl.formatMessage(metaTagMessages.eventSubpageDescription, {
        event: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.eventDescription, {
        event: title,
      });

  return { metaTitle, metaDescription };
}

export function useCompetitionMetaTags(title?: string): MetaTags {
  const { intl, subpage, pathname } = useBaseMetaTags();
  const pathSegments = getPathSegments(pathname, 3);
  const isSubpage = !!pathSegments[2];

  if (!title) {
    return {
      metaTitle: "",
      metaDescription: "",
    };
  }

  const metaTitle = isSubpage
    ? intl.formatMessage(metaTagMessages.competitionSubpageTitle, {
        competition: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.competitionTitle, {
        competition: title,
      });

  const metaDescription = isSubpage
    ? intl.formatMessage(metaTagMessages.competitionSubpageDescription, {
        competition: title,
        subpage,
      })
    : intl.formatMessage(metaTagMessages.competitionDescription, {
        competition: title,
      });

  return { metaTitle, metaDescription };
}
