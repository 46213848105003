import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventTopicsPageQuery as EventTopicsPageQueryType } from "./__generated__/EventTopicsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";

export type { EventTopicsPageQuery as EventTopicsPageQueryType } from "./__generated__/EventTopicsPageQuery.graphql";

export const EventTopicsPageQuery = graphql`
  query EventTopicsPageQuery($slug: String!, $order: VotableOrder!) {
    eventBySlug(slug: $slug) {
      forum {
        ...TopicsPageFragment @arguments(order: $order)
      }
    }
  }
`;

export const eventTopicsPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventTopicsPageQueryType>(
      environment,
      EventTopicsPageQuery,
      { slug, order },
    );
  };
};

export const useEventTopicsPageQuery = () =>
  usePreloaded<EventTopicsPageQueryType>();
