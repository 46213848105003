import { useState } from "react";
import { graphql, useMutation, useLazyLoadQuery } from "react-relay";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import FormGroup from "./FormGroup";
import TextInput from "./TextInput";
import MarkdownEditor from "./MarkdownEditor";
import Button from "./Button";
import type {
  MarkdownEditorContextProps,
  MarkdownValue,
} from "./MarkdownEditor/context";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";
import {
  TopicEditFormFetchWebsiteMetadataMutation,
  TopicEditFormFetchWebsiteMetadataMutation$data,
} from "./__generated__/TopicEditFormFetchWebsiteMetadataMutation.graphql";
import { TopicEditFormQuery as TopicEditFormQueryType } from "./__generated__/TopicEditFormQuery.graphql";
import { commitMutationPromise } from "../utils/relay";

const FetchWebsiteMetadata = graphql`
  mutation TopicEditFormFetchWebsiteMetadataMutation($url: Url!) {
    fetchWebsiteMetadata(url: $url) {
      title
      description
    }
  }
`;

const TopicEditFormQuery = graphql`
  query TopicEditFormQuery {
    ...MemberSelectAllEntitiesAutoCompleteFragment
  }
`;

type FormData = {
  title: string;
  url: string | null;
  description: string;
};

interface DefaultValues {
  title?: string;
  url?: string | null;
  description?: string | null;
}

interface SubmitData {
  data: FormData;
  dirtyFields: Partial<Readonly<Record<keyof FormData, boolean | undefined>>>;
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface Props {
  onSubmit: (data: SubmitData) => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  defaultValues?: DefaultValues;
  guidelines?: string;
}

export default function TopicEditForm({
  onSubmit,
  isDisabled = false,
  isUpdating = false,
  defaultValues,
  guidelines,
}: Props) {
  const query = useLazyLoadQuery<TopicEditFormQueryType>(
    TopicEditFormQuery,
    {},
  );
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue: setFormField,
    trigger: triggerForm,
    control,
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>(
    defaultValues?.description ?? "",
  );
  const doSubmit = handleSubmit((data) => {
    setFormError(undefined);
    onSubmit({ data, dirtyFields, setFormError });
  });
  const errorMessages = {
    title: {
      required: intl.formatMessage({
        defaultMessage: "Title is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Title cannot be longer than 255 characters",
      }),
    },
    url: {
      isValidURL: intl.formatMessage({
        defaultMessage: "URL is not valid",
      }),
    },
    description: {
      maxLength: intl.formatMessage({
        defaultMessage: "Description cannot be longer than 2000 characters",
      }),
    },
  };

  const [containsUrl, setContainsUrl] = useState(!!defaultValues?.url);
  const [fetchWebsiteMetadata, scanningUrl] =
    useMutation<TopicEditFormFetchWebsiteMetadataMutation>(
      FetchWebsiteMetadata,
    );
  const [lastScan, setLastScan] = useState<
    | {
        url: string;
        data: TopicEditFormFetchWebsiteMetadataMutation$data["fetchWebsiteMetadata"];
      }
    | undefined
  >();
  const scanUrl = async (url: string) => {
    if (lastScan?.url === url) return;

    let response;
    try {
      response = await commitMutationPromise(fetchWebsiteMetadata)({
        variables: { url },
      });
    } catch (error) {
      console.error(error);
      setFormError(
        intl.formatMessage({ defaultMessage: "Cannot fetch website data" }),
      );
      return;
    }

    const { fetchWebsiteMetadata: data } = response;
    if (
      control._formValues.title === (defaultValues?.title ?? "") ||
      control._formValues.title === (lastScan?.data.title ?? "")
    ) {
      setFormField("title", data.title ?? "");
    }
    if (
      control._formValues.description === (defaultValues?.description ?? "") ||
      control._formValues.description === (lastScan?.data.description ?? "")
    ) {
      setFormField("description", data.description ?? "");
    }
    setLastScan({ url, data });
  };

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "description",
    control,
    defaultValue: defaultValues?.description ?? "",
    setMarkdownValue,
    markdownValue,
    canUploadFiles: false,
    rules: { maxLength: 2000 },
    members: {
      kind: "all",
      members: query,
    },
  };

  return (
    <form onSubmit={doSubmit}>
      <div className="space-y-6">
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="flex flex-col space-y-3">
          {containsUrl && (
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "URL" })}
              isLoading={scanningUrl}
              error={
                typeof errors.url?.type === "string" &&
                errorMessages.url[
                  errors.url.type as keyof typeof errorMessages.url
                ]
              }
            >
              <TextInput
                aria-invalid={errors.url ? "true" : "false"}
                defaultValue={defaultValues?.url ?? ""}
                readOnly={isUpdating || scanningUrl}
                {...register("url", {
                  setValueAs: (value) => value.trim(),
                  maxLength: 2083,
                  validate: { isValidURL },
                  onBlur: ({
                    target: { value },
                  }: React.FocusEvent<HTMLInputElement>) => {
                    if (isUpdating) return;
                    setFormError(undefined);
                    if (value) {
                      triggerForm("url");
                      if (!isValidURL(value)) return;
                      scanUrl(value);
                    } else {
                      setContainsUrl(false);
                    }
                  },
                })}
              />
            </FormGroup>
          )}
          <FormGroup
            label={
              containsUrl
                ? intl.formatMessage({ defaultMessage: "Title" })
                : intl.formatMessage({ defaultMessage: "Title or URL" })
            }
            isLoading={scanningUrl}
            error={
              typeof errors.title?.type === "string" &&
              errorMessages.title[
                errors.title.type as keyof typeof errorMessages.title
              ]
            }
          >
            <TextInput
              aria-invalid={errors.title ? "true" : "false"}
              defaultValue={defaultValues?.title}
              readOnly={containsUrl && scanningUrl}
              {...register("title", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 255,
                onBlur: ({
                  target: { value },
                }: React.FocusEvent<HTMLInputElement>) => {
                  if (containsUrl || isUpdating) return;
                  if (!value || !isValidURL(value)) return;
                  setContainsUrl(true);
                  setFormField("title", "");
                  setFormField("url", value);
                  scanUrl(value);
                },
              })}
            />
          </FormGroup>
          <MarkdownEditorProvider value={contextValue}>
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "Description" })}
              error={
                typeof errors.description?.type === "string" &&
                errorMessages.description[
                  errors.description
                    .type as keyof typeof errorMessages.description
                ]
              }
            >
              <MarkdownEditor
                rows={10}
                aria-invalid={errors.description ? "true" : "false"}
                placeholder={guidelines}
                readOnly={containsUrl && (isUpdating || scanningUrl)}
              />
            </FormGroup>
          </MarkdownEditorProvider>
          <div className="pt-2 pb-8">
            <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

function isValidURL(x: string | null): boolean {
  if (!x) return true;
  try {
    const url = new URL(x);
    return ["http:", "https:"].includes(url.protocol);
  } catch {
    return false;
  }
}
