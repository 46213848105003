import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";
import { LocationContextValue, useLocation } from "../utils/location";
import { getTopicPath } from "../utils/routing";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";
import {
  ForumHelmetFragment$data,
  ForumHelmetFragment$key,
} from "./__generated__/ForumHelmetFragment.graphql";
import { PropsWithChildren } from "react";

const Fragment = graphql`
  fragment ForumHelmetFragment on Forum {
    id
    title
    slug
    owner {
      __typename
      slug
    }
    shortDescription
    createdAt
    forumTopics: topics(last: 10, order: HOT) {
      nodes {
        id
        title
        description
        createdAt
        author {
          displayName
          username
        }
      }
    }
  }
`;

export interface ForumHelmetProps extends PropsWithChildren {
  forum: ForumHelmetFragment$key;
}

export default function ForumHelmet({
  forum: forumFragment,
  children,
}: ForumHelmetProps) {
  const intl = useIntl();
  const forum = useFragment(Fragment, forumFragment);
  const location = useLocation();

  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "Quantum Discussions | {forumTitle}" },
        { forumTitle: forum.title },
      )}
      metaDescription={intl.formatMessage(
        {
          defaultMessage:
            "Join the {forumTitle} discussion on Aqora and exchange with the global community of quantum experts",
        },
        { forumTitle: forum.title },
      )}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(formatWebPageSchema(forum, location))}
        </script>
      </Helmet>
      {children}
    </MetaLayout>
  );
}

function formatWebPageSchema(
  forum: ForumHelmetFragment$data,
  location: LocationContextValue,
) {
  const forumUrl = location.origin + `/discussions/${forum.title}`;

  const discussionItems = forum.forumTopics.nodes.map((topic) => {
    const topicUrl = new URL(
      getTopicPath({ ...topic, forum }),
      location.origin,
    ).toString();

    return {
      "@type": "DiscussionForumPosting",
      headline: topic.title,
      url: topicUrl,
      author: {
        "@type": "Person",
        name: topic.author.displayName,
        url: new URL(`/${topic.author.username}`, location.origin).toString(),
      },
      dateCreated: topic.createdAt,
    };
  });

  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: forum.title,
    description: forum.shortDescription,
    url: forumUrl,
    discussionUrl: forumUrl,
    mainEntity: {
      "@type": "ItemList",
      itemListElement: discussionItems.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: item,
      })),
    },
  };
}
