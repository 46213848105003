import { useIntl } from "react-intl";
import { cn } from "../utils/tailwind";

function DefaultProfilePic({ alt }: { alt: string }) {
  const intl = useIntl();
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 600"
      width="100%"
      height="100%"
      className="fill-white"
      aria-description={intl.formatMessage({
        defaultMessage: "Default user icon",
      })}
    >
      <title>{alt}</title>
      <circle cx="300" cy="300" r="300" className="fill-indigo" />
      <circle cx="300" cy="230" r="115" />
      <circle cx="300" cy="550" r="205" />
    </svg>
  );
}

interface Props {
  src?: string;
  className?: string;
  alt: string;
}

export default function ProfilePic({ src, alt, className }: Props) {
  if (src) {
    return (
      <img
        className={cn(
          "w-full rounded-full object-cover object-center bg-transparent border-2 border-zinc-200",
          className,
        )}
        src={src}
        alt={alt}
      />
    );
  } else {
    return <DefaultProfilePic alt={alt} />;
  }
}
