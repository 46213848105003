/**
 * @generated SignedSource<<45a6b2aab9e0dcf54cda540cdedb9a4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityKind = "ORGANIZATION" | "USER";
export type EntityEditPageQuery$variables = {
  username: string;
};
export type EntityEditPageQuery$data = {
  readonly entityByUsername: {
    readonly kind: EntityKind;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationEditFormFragment" | "UserEditFormFragment">;
  } | null | undefined;
};
export type EntityEditPageQuery = {
  response: EntityEditPageQuery$data;
  variables: EntityEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedin",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "github",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v11 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isEntity"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserEditFormFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationEditFormFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EntityEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organization",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": "viewerCanDelete",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "DELETE_USER"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"DELETE_USER\")"
              },
              (v11/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": "viewerCanDelete",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "DELETE_ORGANIZATION"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"DELETE_ORGANIZATION\")"
              },
              (v11/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc86bd510600d11c1abdbad1ed9c7ae4",
    "id": null,
    "metadata": {},
    "name": "EntityEditPageQuery",
    "operationKind": "query",
    "text": "query EntityEditPageQuery(\n  $username: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    kind\n    ... on User {\n      ...UserEditFormFragment\n    }\n    ... on Organization {\n      ...OrganizationEditFormFragment\n    }\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_db32b on Entity {\n  __isEntity: __typename\n  displayName\n  image\n}\n\nfragment OrganizationEditFormFragment on Organization {\n  ...EntityProfilePicFragment_db32b\n  id\n  displayName\n  username\n  linkedin\n  github\n  location\n  website\n  bio\n  image\n  viewerCanDelete: viewerCan(action: DELETE_ORGANIZATION)\n}\n\nfragment UserEditFormFragment on User {\n  ...EntityProfilePicFragment_db32b\n  id\n  displayName\n  username\n  linkedin\n  github\n  location\n  website\n  bio\n  image\n  email\n  organization\n  jobTitle\n  viewerCanDelete: viewerCan(action: DELETE_USER)\n}\n"
  }
};
})();

(node as any).hash = "618ad6128cdda0c96165406678ab5ac3";

export default node;
