import { useForumTopicsPageQuery } from "./loaders/ForumTopicsPage";
import ForumHelmet from "../components/ForumHelmet";
import TopicsPage from "./common/TopicsPage";
import ErrorPage from "./ErrorPage";
import { useIntl } from "react-intl";

export default function ForumTopicsPage() {
  const {
    query: { forumBySlug: forum },
    variables: { order: initialOrder },
  } = useForumTopicsPageQuery();
  const intl = useIntl();
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Discussion not found",
        })}
      />
    );
  }

  return (
    <ForumHelmet forum={forum}>
      <TopicsPage forum={forum} initialOrder={initialOrder} />
    </ForumHelmet>
  );
}
