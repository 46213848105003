/**
 * @generated SignedSource<<95edb8957d60abb4bf373ae8e15e27e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationUsersFragmentPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
};
export type OrganizationUsersFragmentPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationUsersFragment">;
  };
};
export type OrganizationUsersFragmentPaginationQuery = {
  response: OrganizationUsersFragmentPaginationQuery$data;
  variables: OrganizationUsersFragmentPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUsersFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "OrganizationUsersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationUsersFragmentPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "viewerCanEdit",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "UPDATE_ORGANIZATION_MEMBERSHIP"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"UPDATE_ORGANIZATION_MEMBERSHIP\")"
              },
              {
                "alias": "viewerCanTransferOwnership",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "TRANSFER_ORGANIZATION_OWNERSHIP"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"TRANSFER_ORGANIZATION_OWNERSHIP\")"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "OrganizationMembershipConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": "viewerCanDelete",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "REMOVE_ORGANIZATION_MEMBER"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"REMOVE_ORGANIZATION_MEMBER\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "imageThumbnail",
                                    "storageKey": null
                                  }
                                ],
                                "type": "Entity",
                                "abstractKey": "__isEntity"
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "OrganizationUsersFragment_users",
                "kind": "LinkedHandle",
                "name": "users"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e849befb4bc7b4b2fd3a053e07b29a3",
    "id": null,
    "metadata": {},
    "name": "OrganizationUsersFragmentPaginationQuery",
    "operationKind": "query",
    "text": "query OrganizationUsersFragmentPaginationQuery(\n  $count: Int = 10\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...OrganizationUsersFragment_1G22uz\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment OrganizationUsersFragment_1G22uz on Organization {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_ORGANIZATION_OWNERSHIP)\n  users(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        kind\n        viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)\n        user {\n          id\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a682d75b8489ba4836183aa2ae91b988";

export default node;
