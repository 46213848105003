import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { useEntitySubmissionsPageQuery } from "./loaders/EntitySubmissionsPage";
import ErrorPage from "./ErrorPage";
import ProfilePic from "../components/ProfilePic";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { FaFolderOpen } from "react-icons/fa6";

export default function EntitySubmissionsPage() {
  const intl = useIntl();
  const {
    variables,
    query: { entityByUsername: user },
  } = useEntitySubmissionsPageQuery();

  if (!user) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }

  return (
    <>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Competition" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Submitted At" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Score" />
            </th>
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>

        <tbody>
          {user.submissions?.nodes.map((node) => {
            const { competition, latest } = node;

            return (
              <tr key={node.id}>
                <td className="whitespace-nowrap py-2 px-3">
                  <Link to={`/competitions/${competition.slug}`}>
                    <div className="w-12 pr-4">
                      <ProfilePic
                        src={competition.banner}
                        alt={intl.formatMessage(
                          { defaultMessage: "{competitionTitle} banner" },
                          { competitionTitle: competition.title },
                        )}
                      />
                    </div>
                    <span>{competition.title}</span>
                  </Link>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  {latest && (
                    <FormattedDate
                      value={latest.createdAt}
                      month="long"
                      day="numeric"
                      year="numeric"
                    />
                  )}
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  {typeof latest?.evaluation?.score === "number" ? (
                    <FormattedNumber value={latest.evaluation.score} />
                  ) : typeof latest?.evaluation?.error === "string" ? (
                    <FormattedMessage defaultMessage="Error" />
                  ) : null}
                </td>
                <td>
                  <Link
                    to={`/${variables.username}/submissions/${node.competition.slug}/latest/code/package`}
                  >
                    <Button
                      kind="text"
                      className="inline-flex items-center space-x-1"
                    >
                      <FaFolderOpen />
                      <span>
                        <FormattedMessage defaultMessage="Browse" />
                      </span>
                    </Button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
