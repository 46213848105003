/**
 * @generated SignedSource<<bd5042dfb5f249a1b4e3474691182b20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageQuery$variables = Record<PropertyKey, never>;
export type HomePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionsFragment" | "EventsFragment">;
};
export type HomePageQuery = {
  response: HomePageQuery$data;
  variables: HomePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "host",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isEntity"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageThumbnail",
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompetitionsFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EventsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateCompetition",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_COMPETITION"
              }
            ],
            "kind": "ScalarField",
            "name": "can",
            "storageKey": "can(action:\"CREATE_COMPETITION\")"
          },
          (v0/*: any*/),
          {
            "alias": "canCreateEvent",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_EVENT"
              }
            ],
            "kind": "ScalarField",
            "name": "can",
            "storageKey": "can(action:\"CREATE_EVENT\")"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompetitionConnection",
        "kind": "LinkedField",
        "name": "competitions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagConnection",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TagEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tag",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": "competitions(first:100)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "CompetitionsFragment_competitions",
        "kind": "LinkedHandle",
        "name": "competitions"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": "events(first:10)"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "EventsFragment_events",
        "kind": "LinkedHandle",
        "name": "events"
      }
    ]
  },
  "params": {
    "cacheID": "47183c6cdd56fa6c4eb284057dda1cfd",
    "id": null,
    "metadata": {},
    "name": "HomePageQuery",
    "operationKind": "query",
    "text": "query HomePageQuery {\n  ...CompetitionsFragment\n  ...EventsFragment\n}\n\nfragment ActivityCardHostFragment on Entity {\n  __isEntity: __typename\n  username\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment ActivityCardTagsFragment on TagConnection {\n  edges {\n    node {\n      id\n      ...CompetitionTagFragment\n    }\n  }\n}\n\nfragment CompetitionCardFragment on Competition {\n  id\n  slug\n  title\n  shortDescription\n  thumbnail\n  host {\n    __typename\n    ...ActivityCardHostFragment\n    id\n  }\n  tags {\n    ...ActivityCardTagsFragment\n  }\n}\n\nfragment CompetitionTagFragment on Tag {\n  name\n}\n\nfragment CompetitionsFragment on Query {\n  viewer @ifAllowed {\n    canCreateCompetition: can(action: CREATE_COMPETITION)\n    id\n  }\n  competitions(first: 100) {\n    edges {\n      node {\n        id\n        ...CompetitionCardFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventCardFragment on Event {\n  id\n  slug\n  title\n  shortDescription\n  thumbnail\n  host {\n    __typename\n    ...ActivityCardHostFragment\n    id\n  }\n}\n\nfragment EventsFragment on Query {\n  viewer @ifAllowed {\n    canCreateEvent: can(action: CREATE_EVENT)\n    id\n  }\n  events(first: 10) {\n    edges {\n      node {\n        id\n        ...EventCardFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "375cf836e0923f90fd190ba17fac6281";

export default node;
