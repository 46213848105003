import { Helmet } from "react-helmet-async";

const DEFAULT_DESCRIPTION =
  "Join Aqora for hands-on quantum computing hackathons and online competitions. Learn, compete, and connect with a global community of experts.";

export default function DefaultHelmet() {
  return (
    <Helmet prioritizeSeoTags htmlAttributes={{ lang: "en" }}>
      <meta charSet="UTF-8" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="shortcut icon" href="/aqora.ico" type="image/x-icon" />
      <title>aqora</title>
      <meta name="description" content={DEFAULT_DESCRIPTION} />
      <meta
        name="og:image"
        content="https://aqora-static.s3.fr-par.scw.cloud/featured-platform.png"
      />
      <meta property="og:title" content="Aqora" />
      <meta property="og:description" content={DEFAULT_DESCRIPTION} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://aqora-static.s3.fr-par.scw.cloud/featured-platform.png"
      />
      <meta property="og:image:alt" content="Aqora banner" />
      <meta property="og:site_name" content="Aqora" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Aqora" />
      <meta name="twitter:description" content={DEFAULT_DESCRIPTION} />
      <meta
        name="twitter:image"
        content="https://aqora-static.s3.fr-par.scw.cloud/featured-platform.png"
      />
      <meta name="twitter:image:alt" content="Aqora banner" />
    </Helmet>
  );
}
