import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EntityOverviewPageQuery as EntityOverviewPageQueryType } from "./__generated__/EntityOverviewPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntityOverviewPageQuery as EntityOverviewPageQueryType } from "./__generated__/EntityOverviewPageQuery.graphql";

export const EntityOverviewPageQuery = graphql`
  query EntityOverviewPageQuery($username: String!) {
    entityByUsername(username: $username) {
      id
      bio
      ...EntityBadgeListFragment
      ...EntityHelmetFragment
    }
  }
`;

export const entityOverviewPageQueryLoader = (environment: Environment) => {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<EntityOverviewPageQueryType>(
      environment,
      EntityOverviewPageQuery,
      { username },
    );
  };
};

export const useEntityOverviewPageQuery = () =>
  usePreloaded<EntityOverviewPageQueryType>();
